import api from "@/common/services/api";

export default new class {
    baseUrl = "/outlets";

    getOutlets(data) {
        return api.instance.get(this.baseUrl, {
            params: data
        });
    }
    getOutlet(id) {
        return api.instance.get(this.baseUrl + '/' + id);
    }
};