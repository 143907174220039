import outletService from "../../services"
// import notificationRouting from "@/common/services/notification-routing"
export default {
    data() {
        return {
            search: "",
            searchedResultShow: false,
            outlets: [],
            meta: {},
            loading: false
        };
    },
    mounted() {
        this.getOutlets();
        this.getNextRecord();
    },
    methods: {
        getOutlets() {
            this.showLoader(true);
            outletService.getOutlets({ "search": this.search }).then(response => {
                this.hideLoader();
                this.outlets = response.data.data;
                this.meta = {
                    current_page: response.data.current_page,
                    from: response.data.from,
                    last_page: response.data.last_page,
                    links: response.data.links,
                    path: response.data.path,
                    per_page: response.data.per_page,
                    to: response.data.to,
                    total: response.data.total
                };
            })
        },
        getNextRecord() {
            window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
                if (bottomOfWindow && this.meta.current_page != this.meta.last_page && !this.loading) {
                    this.showLoader(true);
                    this.loading = true;
                    outletService.getNotifications(
                        {
                            'search': this.search,
                            'page': this.meta.current_page + 1
                        }).then(response => {
                            this.hideLoader();
                            this.outlets = response.data;
                            this.meta = {
                                current_page: response.data.current_page,
                                from: response.data.from,
                                last_page: response.data.last_page,
                                links: response.data.links,
                                path: response.data.path,
                                per_page: response.data.per_page,
                                to: response.data.to,
                                total: response.data.total
                            };
                            this.loading = false;
                        })
                }
            }
        },
        filterUpdate() {
            this.meta.current_page = 1;
            this.getOutlets();
            this.searchedResultShow = true;
        },
        filterClear() {
            this.meta.current_page = 1;
            this.search = "";
            this.getOutlets();
            this.searchedResultShow = false;
        },
    }
}