<app-header>
    <a href="#" class="header-icon header-icon-1 d-flex align-items-center justify-content-center"
        v-if="$store.state.user.user_type != 'super_admin'">
        <i class="fas fa-bell font-22 primary-alt-light-text"></i>
    </a>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Outlets</h1>
    <a href="#" data-menu="searchMenu" class="header-icon header-icon-2">
        <i class="fa fa-search"></i>
    </a>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="text-center" v-if="searchedResultShow">
        <p class="font-15 font-200 my-2 letterSpacing text-secondary text-center text-capitalize">
            Search results for
            <span class="font-500 text-dark">'{{ search }}'</span>
            <button @click="filterClear" class="btn gradient-red btn-xs letterSpacing font-500 ms-3"
                style="padding: 3px 10px !important;">Clear</button>
        </p>
    </div>
    <div v-for="(record, index) in outlets" style="cursor: pointer;">
        <div class="card mx-2 my-2 px-3 py-2">
            <div class="content m-0">
                <router-link :to="{'name': 'outlet-detail', params: {'id' : record.id}}">
                    <div class="d-flex justify-content-between  align-items-center">
                        <p class="primary-alt-light-text text-capitalize font-16 mb-0 font-600 letterSpacing w-100">
                            <i class="fas fa-store-alt  primary-light-text"></i>
                            {{ record.outlet_name }}
                        </p>
                        <p
                            class="d-flex justify-content-center align-item-center font-12 line-height-s text-capitalize mt-1 mb-1 font-500 letterSpacing text-secondary">
                            <i class="far fa-user font-16 me-1 primary-alt-base-text"></i>
                            {{ record.outlet_type }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-between align-items-center">
                        <h2 class="font-12 mb-1 mt-1 text-end text-capitalize letterSpacing font-500"
                            :class="[record.partner_type ? 'partnerTypeBagde': '']">
                            {{ record.address || '-'}}
                        </h2>
                        <p class="font-10 mb-0 text-end">{{ localDate(record.created_at) }}</p>
                        <!-- <i class="fa fa-chevron-right font-10 text-secondary ms-2"></i> -->
                    </div>
                </router-link>
            </div>
        </div>
    </div>
    <div class="mt-5 text-center" v-if="!outlets || outlets.length === 0">
        <i class="fas fa-search font-20 text-secondary mb-3"></i>
        <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
            No outlet Found
        </p>
    </div>
</div>

<!-- search section -->
<div id="searchMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-17 font-200">Search By</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <div class="input-style has-borders">
            <input class="form-control font-15 font-400 letterSpacing" type="text" placeholder="Search "
                v-model="search">
            <em></em>
            <i class="disabled invalid"></i>
            <i class="disabled valid"></i>
        </div>
    </div>
    <div class="content mt-0 d-flex justify-content-center">
        <button @click="filterUpdate" :disabled="!search"
            class="close-menu btn btn-l btn-ripple me-1 primary-light-bg rounded-s letterSpacing">
            Search
        </button>
        <button @click="filterClear" :disabled="!search"
            class="close-menu btn btn-l btn-ripple primary-alt-light-bg rounded-s letterSpacing">
            Clear
        </button>
    </div>
</div>

<app-footer />